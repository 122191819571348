<i18n>
{
  "de": {
    "loading": "Daten werden geladen..."
  }
}
</i18n>

<template>
  <div class="l-main-layout">
    <div sticky-container>
      <LayoutHeader :portfolio="portfolio" />

      <div v-if="ready" class="page-header">
        <slot name="header"></slot>
      </div>

      <div class="compass-contents">
        <div v-if="!ready" class="loading">
          <ClipLoader size="16px" color="#000" />
          <div>{{ $t('loading') }}</div>
        </div>
        <template v-if="ready">
          <slot></slot>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import LayoutHeader from '@/components/shared/LayoutHeader.vue'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  components: {
    LayoutHeader,
    ClipLoader,
  },

  props: {
    portfolio: {
      type: Object,
    },
    ready: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.l-main-layout {
  height: 100svh;

  & > * {
    height: 100%;
    overflow-y: auto;
  }

  & .compass-contents {
    padding: var(--spacing-m) var(--spacing-m) var(--spacing-xl);

    & .loading {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--spacing-xs);
    }
  }
}
</style>
